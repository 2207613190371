(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DocumentService', DocumentService);

    DocumentService.$inject = ['$http', '$q', 'AccountService'];

    function DocumentService($http, $q, AccountService) {

        var service = {
            removeMassDocuments:removeMassDocuments,
            indexAddToDatabase:indexAddToDatabase,
            indexRejectFromDatabase:indexRejectFromDatabase,
            searchUser: null,
            uploadFile: uploadFile,
            uploadMultipleFiles: uploadMultipleFiles,
            uploadText: uploadText,
            save: save,
            searchSimilar: searchSimilar,
            check: check,
            addPriority: addPriority,
            addPriorityFromContract: addPriorityFromContract,
            get: get,
            checkDocumentStatuses: checkDocumentStatuses,
            getDocumentFile: getDocumentFile,
            getRoleOperator: get,
            simpleSearch: simpleSearch,
            getTest: getTest,
            getDocument: getDocument,
            getDocumentAvailableExtension: getDocumentAvailableExtension,
            remove: remove,
            getDetails: getDetails,
            getDetailsForOperator: getDetailsForOperator,
            getMetadataForm: getMetadataForm,
            getDocumentHistory: getDocumentHistory,
            checkIfDocumentCreatedByIndividual: checkIfDocumentCreatedByIndividual,
            getCheckId: getCheckId,
            getDocumentStatus: getDocumentStatus,
            getLanguages: getLanguages,
            isWaitingPayment: isWaitingPayment,
            documentDetailsObj: {},
            isUploaded: false,
            document: {
                id: null
            },
            uploadedData: {},
            editDetails: editDetails,
            operator: {
                removeFromDatabase: removeFromDatabase,
                addToDatabase: addToDatabase,
                withdrawFromDatabase:withdrawFromDatabase,
                rejectFromDatabase:rejectFromDatabase,
                restoreFromDatabase:restoreFromDatabase,
                save: operatorSave,
                searchSimilar: operatorSearchSimilar,
                getConfiguration: operatorGetConfiguration,
                getConfigurationOffChecking: operatorGetConfigurationOffChecking,
                uploadFile: uploadFile,
                uploadMultipleFiles: uploadMultipleFiles,
                uploadText: uploadText,
                checkDocument: checkDocument,
                checkDocumentAgain: checkDocumentAgain,
                recheckAiDetection: recheckAiDetection,
                sendReport: sendReport
            },
            student: {
                get: get,
                simpleSearch: simpleSearch
            },
            lecturer: {
                get: get,
                addToDatabase: addToDatabase,
                removeFromDatabase: removeFromDatabase,
                withdrawFromDatabase:withdrawFromDatabase,
                rejectFromDatabase:rejectFromDatabase,
                restoreFromDatabase:restoreFromDatabase,
                save: lecturerSave,
                getConfiguration: operatorGetConfiguration,
                getConfigurationOffChecking: operatorGetConfigurationOffChecking,
                uploadFile: uploadFile,
                uploadMultipleFiles: uploadMultipleFiles,
                uploadText: uploadText
            },
            universal: {
                get: get,
                addToDatabase: addToDatabase,
                removeFromDatabase: removeFromDatabase,
                withdrawFromDatabase:withdrawFromDatabase,
                rejectFromDatabase:rejectFromDatabase,
                restoreFromDatabase:restoreFromDatabase,
                save: universalSave,
                getConfiguration: universalGetFormConfiguration,
                getConfigurationOffChecking: universalGetFormConfigurationOffChecking,
                uploadFile: uploadFile,
                uploadText: uploadText
            },
            admin: {
                adminRemoveDocuments: adminRemoveDocuments
            }
		};

        return service;

        function simpleSearch(obj) {
            return $http.post('/api/documents/search', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function removeMassDocuments(ids, searchRequest, all) {
            if (AccountService.role.isIndividual()) {
                return all ? individualSearchAndRemoveDocuments(searchRequest) : individualRemoveDocuments(ids);
            }
            if (AccountService.role.isAdmin()) {
                return all ? universalSearchAndRemoveDocuments(searchRequest) : adminRemoveDocuments(ids);
            }
            if (AccountService.role.isLecturer()) {
                return individualRemoveDocuments(ids);
            }
        }

        function indexAddToDatabase(ids, searchRequest, all) {
               return all ? searchAndAddToDatabase(searchRequest) : addToDatabase(ids);
        }

        function indexRejectFromDatabase(ids, searchRequest, all) {
               return all ? searchAndRejectFromDatabase(searchRequest) : rejectFromDatabase(ids);
        }

        function restoreFromDatabase(obj) {
            return $http.put('api/universal/document/index/restore', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function rejectFromDatabase(obj) {
            return $http.put('api/universal/document/index/reject', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function searchAndRejectFromDatabase(obj) {
            return $http.put('api/universal/document/search/index/reject', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function addToDatabase(obj) {
            return $http.put('api/universal/document/index/add', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function searchAndAddToDatabase(obj) {
            return $http.put('api/universal/document/search/index/add', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function removeFromDatabase(obj) {
            return $http.put('api/universal/document/index/remove', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function withdrawFromDatabase(obj) {
            return $http.put('api/universal/document/index/withdraw', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function uploadFile(obj, filename) {
            var request = {
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http.post('api/document/upload-file?filename=' + filename, obj, request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function universalGetFormConfigurationOffChecking(){
            return $http.get('/api/universal/document/upload-fields-information-for-adding-without-checking').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function operatorGetConfigurationOffChecking(){
            return $http.get('/api/operator/document/upload-fields-information-for-adding-without-checking').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDocumentFile(documentId){
            return $http.get('/api/document/file/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function universalGetFormConfiguration(){
            return $http.get('/api/universal/document/upload-fields-information').then(
                function (response) {
                	initDocumentSkipAddresses(response.data);
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function operatorGetConfiguration(documentId){
        	var url = '/api/operator/document/upload-fields-information';
        	if(documentId){
        		url += '?documentId=' + documentId;
        	}
            return $http.get(url).then(
                function (response) {
                	initDocumentSkipAddresses(response.data);
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function initDocumentSkipAddresses(responseData){
        	 if(responseData && responseData.data && responseData.data.documentSkipAddresses==null){
        		 responseData.data.documentSkipAddresses = [];
             }
        }

        function lecturerSave(obj){
            return $http.post('api/lecturer/document/save', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function operatorSave(obj) {
            return $http.post('api/operator/document/save', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function operatorSearchSimilar(obj) {
            return $http.post('api/operator/document/similiar/search', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function uploadFile(obj, filename) {
            var request = {
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http.post('api/document/upload-file?filename=' + filename, obj, request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function uploadMultipleFiles(obj, filename) {
            var request = {
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http.post('api/document/upload-multiple-files?filename=' + filename, obj, request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function uploadText(obj) {
            return $http.post('api/document/upload-text', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function save(obj) {
            return $http.post('api/document/save', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function searchSimilar(obj) {
            return $http.post('api/document/searchForSimilar', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function universalSave(obj) {
            return $http.post('api/universal/document/save', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function editDetails(obj) {
            return $http.post('api/document', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function check(obj) {
            return $http.post('api/document/check', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function addPriority(documentId) {
            return $http.post('api/document/' + documentId + '/add-priority').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function addPriorityFromContract(documentId) {
            return $http.post('api/document/' + documentId + '/add-priority-from-contract').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function get(obj) {
            return $http.post('/api/documents', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function checkDocumentStatuses(obj) {
            return $http.post('/api/documents/statuses', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDetails(id) {
            return $http.get('/api/document/details/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDetailsForOperator(id) {
            return $http.get('/api/operator/document/details/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getMetadataForm(id) {
            return $http.get('/api/operator/document/metadata-form/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDocument(id) {
            return $http.get('/api/document?documentId=' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDocumentAvailableExtension() {
            return $http.get('api/document/available-upload-file-extensions').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getTest(obj) {
            return $http.get('/api/documentsTest').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function remove(obj) {
            return $http.delete('/api/document/' + obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function individualRemoveDocuments(obj) {
            return $http.post('/api/documents/remove', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function individualSearchAndRemoveDocuments(obj) {
            return $http.post('/api/documents/search/remove', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function universalSearchAndRemoveDocuments(obj) {
            return $http.post('/api/universal/documents/search/remove', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function adminRemoveDocuments(obj) {
            return $http.post('/api/admin/documents/remove', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        var success =  function (response) {
            return response.data;
        }

        function getDocumentHistory(documentId) {
            return $http.get('/api/document-history?documentId=' + documentId).then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }

        function checkIfDocumentCreatedByIndividual(documentId) {
            return $http.get('api/document/created-by-individual/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getCheckId(documentId, currency) {
            return $http.get('api/document/check-id?documentId=' + documentId + "&currency=" + currency).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDocumentStatus(documentId) {
            return $http.get('api/document/' + documentId + '/status').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function checkDocument(documentId, codeId) {
            var url = 'api/operator/document/check/' + documentId;
            if (codeId) {
                url = url + '?codeId=' + codeId;
            }
            return $http.post(url).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function checkDocumentAgain(documentId) {
            return $http.post('api/operator/document/check-again/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function recheckAiDetection(documentId) {
            return $http.post('api/operator/document/recheck-ai-detection/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function sendReport(documentId) {
            return $http.post('api/operator/document/send-report/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getLanguages() {
            return $http.get('api/dictionaries/language/uploadDocument').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function isWaitingPayment(documentId) {
            return $http.get('api/document/' + documentId + '/isWaitingPayment').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
