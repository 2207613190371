(function () {
    'use strict';

    function ProfileController($state, $mdDialog, $translatePartialLoader, $localStorage, $attrs, $window,
                               Auth, AccountService, ModalService, UserService) {
        var vm = this;
        vm.showEditForm = showEditForm;
        vm.showChangePasswordForm = showChangePasswordForm;
        vm.logout = logout;
        vm.logoutToRoot = logoutToRoot;
        vm.isOperator = AccountService.role.isOperator();
        vm.isLecturer = AccountService.role.isLecturer();
        vm.showLogoutToRootBtn = $localStorage.showLogoutToRootBtn;
    
        if ($attrs.$attr.showXs && AccountService.forceChangePassword()) {// sprawdzam czy jest ten atrybut showXs komponentu  
            showChangePasswordForm();
        }
        
        function showEditForm() {
        	if(vm.isOperator || vm.isLecturer){
                $mdDialog.show({
                    templateUrl: 'app/components/profile/profile-form-operator/profile-form-operator.dialog.html',
                    clickOutsideToClose: false
                });
        	}else{
	            $mdDialog.show({
	                templateUrl: 'app/components/profile/profile-form.tmpl.html',
	                clickOutsideToClose: false
	            });
        	}
        }

        function showChangePasswordForm() {
            $mdDialog.show({
                templateUrl: 'app/components/profile/change-password.tmpl.html',
                multiple: true,
                clickOutsideToClose: false
            });
        }

        function logout(ev) {
        	if(vm.showLogoutToRootBtn){
        		logoutToRoot(ev);
        	}else{
        		$localStorage.showLogoutToRootBtn = false;
        		$localStorage.logoutToRootInfo = undefined;
            	Auth.logout();
            	$state.go('login');
        	}
        }
        
        function logoutToRoot(ev){
        	UserService.logoutToRoot().then(function(){
        		$localStorage.showLogoutToRootBtn = false;
        		$localStorage.logoutToRootInfo = undefined;
        		$localStorage.returnFromUser = true;
                $window.location.href = '/#/users';
                $window.location.reload();
        	},
        	function(){
        		ModalService.showSuccessModal("global.operation.failed", ev);
        	});
        }
        $translatePartialLoader.addPart('delete-account');

    }

    angular.module('plagiat2017App')
    .component('profile', {
        templateUrl: 'app/components/profile/profile.template.html',
        controller: ProfileController
    });

})();
