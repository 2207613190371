(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('LandingService', LandingService);

    LandingService.$inject = ['$http', '$q'];

    function LandingService($http, $q) {


        var service = {
            registerAndLogin: registerAndLogin,
            addDocumentFromLanding: addDocumentFromLanding,
            uploadFile: uploadFile,
            getTokenPriceList: getTokenPriceList,
            getSingleTokenPrince: getSingleTokenPrince,
            getAvailableFileExtensions: getAvailableFileExtensions
        };

        return service;

        function registerAndLogin(obj) {
            return $http.post('api/landing-page/register-and-login-user', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getTokenPriceList() {
            return $http.get('api/landing-page/tokens-pricelist').then(
              function (response) {
                  return response.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }

        function getSingleTokenPrince(currency, paymentType) {
            return $http.get('api/landing-page/single-token-price?currency=' + currency + '&paymentType=' + paymentType).then(
              function (response) {
                  return response.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }

        function getAvailableFileExtensions() {
            return $http.get('api/landing-page/available-file-extensions').then(
              function (response) {
                  return response.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }

        function addDocumentFromLanding(fileId, extraOptions, currency, paymentType, autoTranslation, invoiceData) {
            var url = 'api/landing-page/add-document/?fileId=' + fileId + '&extraOptions=' + extraOptions + '&currency=' + currency + '&paymentType=' + paymentType;
            if (autoTranslation !== null && autoTranslation.chosen) {
                url = url + '&autoTranslationTo=' + autoTranslation.autoTranslationsToLang;
            }
            if (invoiceData) {
                return $http.post(url, invoiceData)
                    .then(
                        function (response) {
                            return response.data;
                        },
                        function (errResponse) {
                            return $q.reject(errResponse);
                        });
            } else {
                return $http.post(url)
                    .then(
                        function (response) {
                            return response.data;
                        },
                        function (errResponse) {
                            return $q.reject(errResponse);
                        });
            }
        }

        function uploadFile(obj, fileName) {
            var request = {
                method: 'POST',
                url: 'api/landing-page/upload-file?fileName=' + fileName,
                data: obj,
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http(request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
