(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('StatisticsController', StatisticsController);

    StatisticsController.$inject = [
        'STATISTIC_OPTIONS', 'StatisticsService', 'AccountService', 'OPDOCUSTATUSES', 'SIMPLE_SEARCH_DELAY',
        'ClientService', 'SearchService', 'ClientQueryRequest', 'OrganisationalUnitsService', 'ModalService'];

    function StatisticsController(
        STATISTIC_OPTIONS, StatisticsService, AccountService, OPDOCUSTATUSES, SIMPLE_SEARCH_DELAY,
        ClientService, SearchService, ClientQueryRequest, OrganisationalUnitsService, ModalService
    ) {
        var vm = this;
        vm.activeTab = 'statistics';
        
        vm.clientSettings = AccountService.getClientSettings();
        if (vm.clientSettings.hideDocuSimilarityFactor && vm.clientSettings.hideDocuSimilarityFactorExt) {
            vm.noCoefficients = true;
        }
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.options = STATISTIC_OPTIONS;
        vm.option = vm.noCoefficients ? STATISTIC_OPTIONS.DOCUMENTS_CHECKED : STATISTIC_OPTIONS.COEFFICIENT_DISTRIBUTION;
        vm.dateTo = new Date();
        vm.dateFrom = new Date(vm.dateTo.getFullYear(), vm.dateTo.getMonth(), 1);

        vm.coefficientDistributionOptions = {
            organisationalUnitId: null,
            similarityFactor: !vm.clientSettings.hideDocuSimilarityFactor ? 1 : (!vm.clientSettings.showReversedSimilarityFactor ? 2 : 3),
            similarityFrom: 0,
            similarityTo: 100,
            docStatus: 1,
            step: 20,
            alerts: -1
        };

        vm.jsa = AccountService.getClientSettings().jsaIntegration;

        vm.searchRequest = {};
        vm.sumCharacters = sumCharacters;
        vm.sumPriorityCharacters = sumPriorityCharacters;
        vm.sumDocuments = sumDocuments;
        vm.generateExcelFile = generateExcelFile;
        vm.querySearchClients = querySearchClients;
        vm.clientQueryRequest = new ClientQueryRequest();
        vm.isAdmin = AccountService.role.isAdmin();
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();

        vm.aiDetectionEnabled = AccountService.account.contractAiDetectionEnabled || vm.isSuperAdmin;
        vm.showStatisticPriorityColumn = vm.isSuperAdmin;

        vm.searchRequest.page = 1;
        vm.page = 1;
        vm.size = 20;

        vm.generate = generate;
        vm.change = change;
        vm.pageChange = pageChange;

        this.$onInit = function () {
            findOrganisationalUnits();
            vm.docStatuses = OPDOCUSTATUSES;
        };

        function findOrganisationalUnits() {
            if (vm.isSuperAdmin && vm.selectedClient) {
                OrganisationalUnitsService.findAllByClient(vm.selectedClient.id).then(function (response) {
                    vm.organisationalUnits = response.data;
                    vm.coefficientDistributionOptions.organisationalUnitId = vm.organisationalUnits[0].id;
                });
            } else if (!vm.isSuperAdmin) {
                OrganisationalUnitsService.findAllForClient().then(function (response) {
                    vm.organisationalUnits = response.data;
                    vm.coefficientDistributionOptions.organisationalUnitId = vm.organisationalUnits[0].id;
                });
            }
        }

        function checkIfParamsValid() {
            if (vm.dateTo < vm.dateFrom) {
                ModalService.showErrorModal({data: {code: 110}});
                return false;
            }
            return true;
        }
        
        function generate() {
            if (!checkIfParamsValid()) {
                return;
            }
            
            vm.loading = true;
            vm.searchRequest.dateFrom = vm.dateFrom;
            vm.searchRequest.dateTo = vm.dateTo;
            vm.searchRequest.page = vm.page - 1;
            vm.searchRequest.size = vm.size;
            vm.searchRequest.clientId = vm.selectedClient ? vm.selectedClient.id : null;

            if (vm.option == vm.options.COEFFICIENT_DISTRIBUTION) {
                vm.searchRequest.organisationalUnitId = vm.coefficientDistributionOptions.organisationalUnitId;
                vm.searchRequest.similarityFactor = vm.coefficientDistributionOptions.similarityFactor;
                vm.searchRequest.similarityFrom = vm.coefficientDistributionOptions.similarityFrom;
                vm.searchRequest.similarityTo = vm.coefficientDistributionOptions.similarityTo;
                vm.searchRequest.docStatus = vm.coefficientDistributionOptions.docStatus;
                vm.searchRequest.step = vm.coefficientDistributionOptions.step;
                vm.searchRequest.alerts = vm.coefficientDistributionOptions.alerts;
            }

            StatisticsService.getStatistics(vm.option.url, vm.searchRequest).then(function (data) {
                vm.data = data.data;
                vm.loading = false;
            });
            if (vm.option == vm.options.UNIT_CONTRACT || vm.option == vm.options.USER_CONTRACT) {
                vm.showStatisticPriorityColumn = vm.clientSettings.addPriorityFromContractEnabled || vm.data.charactersPrioritySum > 0 || vm.isSuperAdmin;
            }
        }

        function change() {
            vm.data = undefined;
        }

        function pageChange(page) {
            vm.page = page;
            generate();
        }

        vm.clientChange = function () {
            findOrganisationalUnits();
        };

        function sumCharacters(array) {
            return array.reduce(function (sum, unit) {
                return sum + unit.characterCount;
            }, 0);
        }

        function sumPriorityCharacters(array) {
            return array.reduce(function (sum, unit) {
                return sum + unit.characterPriorityCount;
            }, 0);
        }

        function sumDocuments(array) {
            return array.reduce(function (sum, unit) {
                return sum + unit.documentCount;
            }, 0);
        }

        function generateExcelFile() {
            vm.searchRequest.dateFrom = vm.dateFrom;
            vm.searchRequest.dateTo = vm.dateTo;
            vm.searchRequest.page = vm.page - 1;
            vm.searchRequest.size = 10000;
            StatisticsService.generateStatisticsFile(vm.option.url, vm.searchRequest);
        }

        function querySearchClients(query) {
            vm.searchRequest.pageNumber = 0;
            vm.searchRequest.pageSize = 20;
            vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
            vm.searchRequest.search = query;
            vm.searchRequest.simpleSearch = query;

            return ClientService.searchClients(vm.searchRequest).then(function (response) {
                return response.content;
            });
        }
    }
})();
