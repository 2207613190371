(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('UsersController', UsersController);

    UsersController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'UserService',
    'QueryRequest', 'SearchRequest', 'UserSearchRequest', 'tableSettingsRequest',
    'InstanceService', 'DetectInstanceService', '$cookies', '$stateParams', '$log', 'SearchService', 'PlagiatValidationService',
    '$mdBottomSheet', '$mdToast', 'TableSelectOptions', '$q', '$mdDialog', '$localStorage', 'UserReportXlsService', 'AccountService', 'USERROLES', 'USERSCHOOLTYPE'];

    function UsersController (Principal, Auth, JhiLanguageService, $translate, UserService,
      QueryRequest, SearchRequest, UserSearchRequest, tableSettingsRequest,
      InstanceService, DetectInstanceService, $cookies, $stateParams, $log, SearchService, PlagiatValidationService,
      $mdBottomSheet, $mdToast, TableSelectOptions, $q, $mdDialog, $localStorage, UserReportXlsService, AccountService, USERROLES, USERSCHOOLTYPE
    ) {

        var vm = this;
        vm.showUserImport = false;
        vm.simpleSearch = true;
        vm.activeTab='users';
        vm.instance = InstanceService.instance().lang;
        vm.conversionDomain = DetectInstanceService.getConversionAllowance();
        vm.testPoolVisible = !AccountService.clientType.isPap();
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.key = $stateParams.key;
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.limit = $localStorage.usersListlimit ? $localStorage.usersListlimit : vm.queryRequest.limit;
        vm.searchRequest = new SearchRequest();
        vm.userSearchRequest = new UserSearchRequest();
        vm.options = new tableSettingsRequest();
        vm.selected = [];
        vm.userQueryCounters = [];
        vm.pickedUser = false;
        vm.showDetailsBlock = false;

        vm.pagingAction = pagingAction;
        vm.getUsers = getUsers;
        vm.getDetails = getDetails;
        vm.editUserQueryCounter = editUserQueryCounter;
        vm.clickOnThePageListener = clickOnThePageListener;
        vm.cancelEditUserQueryCounter = cancelEditUserQueryCounter;
        vm.saveUserQueryCounter = saveUserQueryCounter;
        vm.clear = clear;
        vm.searchUsers = searchUsers;
        vm.userQueryCountersKeyPress = userQueryCountersKeyPress;
        vm.userQueryCountersKeyUp = userQueryCountersKeyUp;
        vm.tableSelectOptions = new TableSelectOptions();
        vm.selected = [];
        vm.deSelectRow = deSelectRow;
        vm.selectRow = selectRow;
        vm.toggleSelection = toggleSelection;
        vm.selectRows = selectRows;
        vm.openBottomSheet = openBottomSheet;
        vm.closeBottomSheet = closeBottomSheet;
        vm.hibernateUsers = hibernateUsers;
        vm.massRemindPassword = massRemindPassword;
        vm.setCounters = setCounters;
        vm.setCountersCallback = setCountersCallback;
        vm.generateReportXls = generateReportXls;
        vm.removeUsers = removeUsers;
        vm.getUserRole = getUserRole;
        vm.USERSCHOOLTYPES = USERSCHOOLTYPE;

        this.$onInit = function () {
            vm.showUserImport = !AccountService.clientType.isPap();
            vm.loggedUserIsMainAdmin = AccountService.role.isMainAdmin();
            vm.loggedUserIsOrgUnitAdmin = AccountService.role.isOrgUnitAdmin();
            vm.loggedUserIsAdmin = AccountService.role.isAdmin();
            vm.isReseller = AccountService.role.isReseller();
            vm.isMinistry = AccountService.role.isMinistry();
            vm.loggedUserIsSuperAdmin = AccountService.role.isSuperAdmin();
            vm.loggedUserIsMainSuperAdmin = AccountService.role.isMainSuperAdmin();
            vm.jsaIntegration = AccountService.getClientSettings().jsaIntegration;
            if (UserService.searchObject) {
                vm.searchRequest.search = UserService.searchObject;
                vm.simpleSearch = vm.searchRequest.simpleSearch.length > 0;
                vm.userSearchRequest = vm.searchRequest.search;
                UserService.searchObject = null;
            } else if($localStorage.userSearchRequest){
                vm.searchRequest = $localStorage.userSearchRequest;
                vm.simpleSearch = vm.searchRequest.simpleSearch.length > 0;
                vm.userSearchRequest = vm.searchRequest.search;
            }
            getUsers();
        };
        
        function getUserRole(user) {
            const userRole = angular.copy(USERROLES.getAllUserRoles()
                .find(function (role) {
                    return role.type.id === user.userSchoolType;
                }));
            if (user.userSchoolType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.id && user.mainAdmin) {
                userRole.translationKey = 'users.model.role.main.admin';
            }
            return userRole;
        }

        function prepareMassActions() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                if( value.userStatusEnum === 'PRESENT' ){
                    vm.tableSelectOptions.ids.push(value.id);
                }
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

        function prepareMassActionsForRemindPassword() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                if( value.userStatusEnum === 'PRESENT' || value.userStatusEnum === 'INACTIVE_NOT_HIBERNATE' ){
                    vm.tableSelectOptions.ids.push(value.id);
                }
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

        function prepareMassActionsForSetCounters() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                vm.tableSelectOptions.ids.push(value.id);
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

        function removeUsers() {
            prepareMassActions().then(function(response){
                var confirm = $mdDialog.confirm()
                .title($translate.instant("global.confirm.action"))
                .htmlContent($translate.instant("global.selected") + response.selectedNo)
                .ariaLabel($translate.instant("document.areyousure"))
                .ok($translate.instant("global.button.remove"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    vm.isLoaderActive = true;
                    UserService.verifyRemoveUsers({ids: vm.selected}, vm.searchRequest, response.selectedAll).then(function () {
                        vm.isLoaderActive = false;
                        closeBottomSheet();
                        getUsers();
                    }).catch(function (response) {
                        PlagiatValidationService.getModalError(response.data);
                    });
                });
            });
        }

        function hibernateUsers() {
            prepareMassActions().then(function(response) {
                vm.infoText = response.selectedAll
                    ? $translate.instant("users.info.selected") + vm.selected.length +'<br /><br />'+ $translate.instant("users.info.hibernate.all")
                    : $translate.instant("users.info.selected") + vm.selected.length +'<br />'+ $translate.instant("users.info.to.hibernate") + response.ids.length + '<br /><br />' + $translate.instant("users.info.cant.hibernate");
                var confirm = $mdDialog.confirm()
                .title($translate.instant("global.confirm.action"))
                .htmlContent(vm.infoText)
                .ariaLabel($translate.instant("user.areyousure"))
                .ok($translate.instant("global.button.hibernate"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    if( vm.tableSelectOptions.ids.length > 0 || response.selectedAll ){
                        vm.isLoaderActive = true;
                        UserService.verifyHibernateUsers({ids:response.ids}, vm.searchRequest, response.selectedAll).then(function () {
                            vm.isLoaderActive = false;
                            closeBottomSheet();
                            getUsers();
                        }, function () {
                         PlagiatValidationService.getModalError(response.data);
                     });
                    }
                });
            });
        }

        function DialogController($scope, $mdDialog, items) {
            $scope.items = items;
        }

        function setCountersCallback(value) {
            $mdDialog.hide();

            prepareMassActionsForSetCounters().then(function(response) {
                vm.infoText = response.selectedAll
                    ? $translate.instant("users.info.set.counters.all") +'<br /><br />'
                    : $translate.instant("users.info.selected") + vm.selected.length +'<br />'+ $translate.instant("users.info.set.counters.confirmation") + '<br />';
                var confirm = $mdDialog.confirm()
                    .title($translate.instant("global.confirm.action"))
                    .htmlContent(vm.infoText)
                    .ariaLabel($translate.instant("user.areyousure"))
                    .ok($translate.instant("users.set.counters"))
                    .cancel($translate.instant("global.button.cancel"))
                    .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    if( vm.tableSelectOptions.ids.length > 0 || response.selectedAll ){
                        vm.isLoaderActive = true;
                        UserService.setCounters({ids:response.ids}, vm.searchRequest, response.selectedAll, value).then(function () {
                            vm.isLoaderActive = false;
                            closeBottomSheet();
                            getUsers();
                        }, function () {
                            PlagiatValidationService.getModalError(response.data);
                        });
                    }
                });
            });
        }

        function setCounters() {
            $mdDialog.show({
                templateUrl: 'app/account/users/set-counters/set-counters.dialog.html',
                clickOutsideToClose: true,
                locals: {
                    items: {
                        setCountersCallback: setCountersCallback
                    }
                },
                controllerAs: 'vm',
                controller: DialogController
            });
        }

        function massRemindPassword() {
            prepareMassActionsForRemindPassword().then(function(response) {
                vm.infoText = response.selectedAll
                    ? $translate.instant("users.info.selected") + vm.users.length
                    : $translate.instant("users.info.selected") + vm.selected.length +'<br />'+ $translate.instant("users.info.to.remind.password") + response.ids.length + '<br /><br />' + $translate.instant("users.info.cant.remind.password");
                var confirm = $mdDialog.confirm()
                    .title($translate.instant("global.confirm.action"))
                    .htmlContent(vm.infoText)
                    .ariaLabel($translate.instant("user.areyousure"))
                    .ok($translate.instant("users.remind.password"))
                    .cancel($translate.instant("global.button.cancel"))
                    .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    if( vm.tableSelectOptions.ids.length > 0 || response.selectedAll ){
                        vm.isLoaderActive = true;
                        UserService.verifyMassRemindPassword({ids:response.ids}, vm.searchRequest, response.selectedAll).then(function () {
                            vm.isLoaderActive = false;
                            closeBottomSheet();
                            getUsers();
                        }, function () {
                            PlagiatValidationService.getModalError(response.data);
                        });
                    }
                });
            });
        }

        function toggleSelection(total) {
            if(!vm.tableSelectOptions.selectedAll) {
                selectRows(vm.users);
            }
            vm.tableSelectOptions.selectedAll = !vm.tableSelectOptions.selectedAll;
            vm.tableSelectOptions.selectedNo = total;
            if( !vm.tableSelectOptions.selectedAll ) {
                closeBottomSheet();
            }
        }

        function deSelectRow() {
            if( vm.selected.length === 0) {
                closeBottomSheet();
            }
        }

        function selectRow(pickedId) {
            if( vm.selected.length === 1) {
                openBottomSheet();
            }
            angular.forEach(vm.users, function (value) {
                if (value.id === pickedId) {
                    if (vm.tableSelectOptions.selectedHelper.indexOf(pickedId) === -1) {
                        vm.tableSelectOptions.selectedHelper.push(value);
                    }
                }
            });
        }

        function selectRows(obj) {
            angular.forEach(obj, function (value) {
                if (vm.selected.indexOf(value.id) === -1) {
                    vm.selected.push(value.id);
                }
            })
        }

        function resetSelection() {
            if(vm.tableSelectOptions.selectedAll) {
                vm.selected.length = 0;
                vm.tableSelectOptions = new TableSelectOptions();
                $mdBottomSheet.hide();
            }
        }

        function closeBottomSheet() {
                vm.selected.length = 0;
                vm.tableSelectOptions = new TableSelectOptions();
                $mdBottomSheet.hide();
        }

        function openBottomSheet() {
            $mdBottomSheet.show({
              templateUrl: 'app/account/users/bottom-sheet-grid-template.html',
              controllerAs: 'bootomSheet',
              parent: angular.element(document.getElementById('users')),
              controller: function GridBottomSheetCtrl(){
                var bootomSheet = this;
                bootomSheet.checked = true;
                bootomSheet.closeBottomSheet = vm.closeBottomSheet;
                bootomSheet.selected = vm.selected;
                bootomSheet.tableSelectOptions = vm.tableSelectOptions;
                bootomSheet.total = vm.totalUsers;
                bootomSheet.toggleSelection = vm.toggleSelection;
                bootomSheet.hibernate = vm.hibernateUsers;
                bootomSheet.remove = vm.removeUsers;
                bootomSheet.remindPasswords = vm.massRemindPassword;
                bootomSheet.setCounters = vm.setCounters;
                bootomSheet.generateReportXls = vm.generateReportXls;
                bootomSheet.isReseller = vm.isReseller;
                bootomSheet.isMinistry = vm.isMinistry;
                bootomSheet.isSuperAdmin = vm.loggedUserIsSuperAdmin;
            },
            isLockedOpen: true,
            disableParentScroll: false,
            disableBackdrop: true
          });
        }

        function clickOnThePageListener(event){
            vm.clickedElement = event.target;
            if (!vm.clickedElement) return;

            var elementClasses = vm.clickedElement.classList;
            var clickedInsideOfUserQueryCounter = elementClasses.contains('user-query-counter-input') || elementClasses.contains('user-query-counter-block')
                || elementClasses.contains('icon-save') || elementClasses.contains('icon-cancel') || elementClasses.contains('icon-edit');
            if(!clickedInsideOfUserQueryCounter){
                vm.userQueryCounterId = undefined;
                resetUserQueryCounters();
            }
        }
        
        function getUsers(){
            vm.showDetailsBlock = false;
            vm.searchRequest.pageNumber = vm.queryRequest.page-1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            vm.searchRequest.search = vm.userSearchRequest;
            vm.getOrSearch = vm.searchRequest.simpleSearch.length > 0 ? function (s) {
                return UserService.searchUsers(s);
            } : function (s) {
                return UserService.getUsers(s);
            };
            vm.isLoaderActive = true;
            vm.getOrSearch(vm.searchRequest).then(function (responseData) {
                vm.responseObj = responseData.content;
                vm.users = vm.responseObj;
                vm.totalUsers = responseData.totalElements;
                vm.isLoaderActive = false;
                UserService.searchObject = null;
            });

        }

        function searchUsers(){
            vm.queryRequest.page = 1;
            getUsers(); 
        }
        
        function pagingAction(page) {
            resetSelection();
        	vm.isLoaderActive = true;
            vm.queryRequest.page = page;
            getUsers();
        }

        function getDetails(user) {
        	vm.showDetailsBlock = false;
        	
        	if (user.id !== UserService.userDetailsObj.id) {
	            vm.pickedUser = user.id;
	            UserService.userDetailsObj = user;
	            UserService.getDetails(user.id).then(function (result) {
	                vm.showDetailsBlock = true;
	                vm.details = result.data;
	            }); 
        	} else {
        		UserService.userDetailsObj = {};
        	}
        }
        
        function resetUserQueryCounters(){
        	if(vm.users){
        		angular.forEach(vm.users, function(user, index){
        			vm.userQueryCounters[index] = user.userQueryCounter;    
        		})
        	}
        }

        function editUserQueryCounter(user, index, event) {
            if (vm.isMinistry) {
                return;
            }
        	vm.clickedElement = event.target;
            if (!vm.clickedElement) return;
            var elementClasses = vm.clickedElement.classList;
            vm.clickedCancelOrSave = elementClasses.contains('icon-save') || elementClasses.contains('icon-cancel');
            if (vm.clickedCancelOrSave){
            	return;
            }
            vm.userQueryCounterId = user.id;
        }

        function cancelEditUserQueryCounter(){
        	vm.userQueryCounterId = undefined;
        	resetUserQueryCounters();
        }
        
        function saveUserQueryCounter(user, index){
        	vm.userQueryCounterSaving = true;
        	vm.form = {
            	id: vm.userQueryCounterId,
            	userQueryCounterStr: vm.userQueryCounters[index]
            };
        	UserService.updateUserQueryCounter(vm.form).then(function(){
        		vm.userQueryCounterId = undefined;
        		vm.userQueryCounterSaving = false;
        		vm.users[index].userQueryCounter = vm.userQueryCounters[index];
        	}, function(response){
        		vm.userQueryCounterId = undefined;
        		vm.userQueryCounterSaving = false;
        		resetUserQueryCounters();
        		PlagiatValidationService.getModalError(response.data);
        	})
        }

        function clear() {
            vm.userSearchRequest = new UserSearchRequest();
            getUsers();
        }
        
        function userQueryCountersKeyPress(event){
        	if (event.target.value.length >= event.target.maxLength || !(event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))){
        		event.preventDefault();
        	}
        }
        
        function userQueryCountersKeyUp(event){
        	if (event.target.value.length >= event.target.maxLength){
        		event.target.value = event.target.value.slice(0, event.target.maxLength);
        	}
        }

        function generateReportXls(){
        	prepareMassActions().then(function(response){
        		UserReportXlsService.generateReportXls(response, vm.selected, vm.searchRequest);
        	});
        }
   }

})();
