(function() {
	'use strict';
	angular.module('plagiat2017App')
        .factory('DocumentProtocolService', DocumentProtocolService);

	DocumentProtocolService.$inject = [ '$http', '$q', 'FileSaver', 'PlagiatValidationService', 'AccountService'];

	function DocumentProtocolService($http, $q, FileSaver, PlagiatValidationService, AccountService) {
		var service = {
            generateProtocolForDocuments: generateProtocolForDocuments
		};
		return service;

		function generateProtocolForDocuments(documentId, protocolForDocumentId, fileName) {
			var url = 'api/protocols/protocol-for-document/generate/' + documentId + ',' + protocolForDocumentId
			+ ',' + AccountService.account.langKey;
			return $http({
				url : url,
				method : "GET",
				responseType : 'arraybuffer'
			}).then(
			    function(success) {
                    onSuccess(success, fileName, '.pdf');
                },
                function(error) {
                    PlagiatValidationService.getModalError(error);
                }
            );
		}

        function onSuccess(success, fileName, reportExtension) {
            var blob = new Blob(
                [ success.data ],
                {
                    type: "text/pdf; encoding=UTF-8"
                }
            );
            saveAs(blob, fileName + reportExtension);
        }
	}

})();
