(function() {
	'use strict';

	function UserDetailsController($log, $state, DocumentService, AccountService) {
		var vm = this;

		vm.hideUserHistory = AccountService.account.clientSettings.hideUserHistory;
		
		vm.getDocuments = getDocuments;

		function getDocuments(user){
			DocumentService.searchUser = user;
			$state.go('documents');
		}

	}

	angular
	.module('plagiat2017App')
	.component('userDetails', {
			templateUrl : 'app/account/users/user-details/user-details.template.html',
			controller : UserDetailsController,
			bindings: {
				onUser: '<',
				onUserDetails: '<',
            	onIsSuperadmin: '<',
				onGetUserRole: '<'
			}
		});

})();
