(function () {
    'use strict';

    angular.module('plagiat2017App').controller('PaymentsInvoicesController',
        PaymentsInvoicesController);

    PaymentsInvoicesController.$inject = ['$localStorage', 'InvoicesQueryRequest', 'tableSettingsRequest',
        'SearchRequest', 'SearchService', 'InvoicesService', 'ModalService', 'PaymentsService', 'DetectInstanceService',
        'PlagiatValidationService', 'INVOICE_STATUSES'];

    function PaymentsInvoicesController($localStorage, InvoicesQueryRequest, tableSettingsRequest,
                                        SearchRequest, SearchService, InvoicesService, ModalService, PaymentsService, DetectInstanceService,
                                        PlagiatValidationService, INVOICE_STATUSES) {
        var vm = this;
        vm.activeTab = 'paymentsInvoices';

        vm.queryRequest = new InvoicesQueryRequest();
        vm.queryRequest.limit = $localStorage.invoicesListlimit ? $localStorage.invoicesListlimit : vm.queryRequest.limit;
        vm.searchRequest = new SearchRequest();
        vm.searchRequest.search.status = 1;
        vm.searchRequest.search.romanian = DetectInstanceService.instance() === 'ro';
        vm.options = new tableSettingsRequest();
        vm.selected = [];
        vm.pickedIds = [];
        vm.pickedInvoice = false;
        vm.showDetailsBlock = false;
        vm.details = {};
        vm.invoiceDetailsObj = {};
        vm.simpleSearch = true;
        vm.invoiceStatuses = INVOICE_STATUSES;

        vm.getInvoices = getInvoices;
        vm.issueInvoice = issueInvoice;
        vm.downloadInvoice = downloadInvoice;
        vm.markInvoiceAsIssued = markInvoiceAsIssued;
        vm.markInvoiceAsWaiting = markInvoiceAsWaiting;
        vm.generateBulkInvoice = generateBulkInvoice;
        vm.selectInvoice = selectInvoice;
        vm.pagingAction = pagingAction;
        vm.clear = clear;
        vm.getInvoiceDetails = getInvoiceDetails;

        function getInvoices() {
            clearSelection();
            vm.showDetailsBlock = false;
            vm.searchRequest.pageNumber = vm.queryRequest.page - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            vm.isLoaderActive = true;
            InvoicesService.getInvoicesForSuperadmin(vm.searchRequest).then(function (responseData) {
                vm.responseObj = responseData.content;
                vm.invoices = vm.responseObj;
                vm.totalInvoices = responseData.totalElements;
                vm.isLoaderActive = false;
            });
        }

        function getInvoiceDetails(row) {
            vm.showDetailsBlock = false;

            if (row.id !== vm.invoiceDetailsObj.id) {
                vm.pickedInvoice = row.id;
                vm.invoiceDetailsObj = row;
                InvoicesService.getInvoiceDetails(row.id).then(function (result) {
                    vm.showDetailsBlock = true;
                    vm.details = result.data;
                });
            } else {
                vm.invoiceDetailsObj = {};
            }
        }

        function pagingAction(page) {
            vm.isLoaderActive = true;
            vm.queryRequest.page = page;
            vm.getInvoices();
        }

        function clear() {
            vm.searchRequest = new SearchRequest();
            vm.getInvoices();
        }

        function markInvoiceAsIssued(invoice) {
            ModalService.showConfirmModal().then(function () {
                vm.isLoaderActive = true;
                InvoicesService.markInvoiceAsIssued(invoice.id).then(
                    function () {
                        vm.getInvoices();
                        ModalService.showSuccessModal();
                    },
                    function (response) {
                        PlagiatValidationService.getModalError(response.data);
                        vm.isLoaderActive = false;
                    });
            });
        }
    
        function markInvoiceAsWaiting(invoice) {
            ModalService.showConfirmModal().then(function () {
                vm.isLoaderActive = true;
                InvoicesService.markInvoiceAsWaiting(invoice.id).then(
                    function () {
                        vm.getInvoices();
                        ModalService.showSuccessModal();
                    },
                    function (response) {
                        PlagiatValidationService.getModalError(response.data);
                        vm.isLoaderActive = false;
                    });
            });
        }
    
        function generateBulkInvoice() {
            ModalService.showConfirmModal().then(function() {
                vm.isLoaderActive = true;
                transformSelectedObj();
                InvoicesService.generateBulkInvoice(vm.pickedIds).then(
                    function () {
                        clearSelection();
                        vm.getInvoices();
                        ModalService.showSuccessModal();
                    },
                    function (response) {
                        PlagiatValidationService.getModalError(response.data);
                        vm.isLoaderActive = false;
                    });
            });
        }
    
        function issueInvoice(invoice) {
            ModalService.showConfirmModal().then(function () {
                vm.isLoaderActive = true;
                InvoicesService.issueInvoice(invoice.id).then(
                    function () {
                        vm.getInvoices();
                        ModalService.showSuccessModal();
                    },
                    function (response) {
                        PlagiatValidationService.getModalError(response.data);
                        vm.isLoaderActive = false;
                    });
            });
        }
    
        function downloadInvoice(invoiceNumber) {
            PaymentsService.downloadInvoice(invoiceNumber).then(
                function () {},
                function (response) {
                    PlagiatValidationService.getModalError(response.data);
                });
        }
    
        function transformSelectedObj() {
            vm.pickedIds.length = 0;
            angular.forEach(vm.selected, function (value) {
                vm.pickedIds.push(value.id);
            });
        }
    
        function selectInvoice(invoice) {
            if (invoice.status != 3) { // pozwalamy na zaznaczanie tylko ze statusem 3
                vm.selected = vm.selected.filter(function(el) {
                    return el.id != invoice.id;
                });
            }
        }
        
        function clearSelection() {
            vm.selected = [];
            vm.pickedIds = [];
        }
        
        vm.getInvoices();

    }
})();
