(function () {
    'use strict';

    function JsaDocumentFormUniversalController(
        $scope, $q, _, $rootScope, $state, $translate, $mdDialog, DocumentClientModel, DocumentService, JsaService,
        ContractService, $log, $timeout, AccountService, PlagiatValidationService, DuplicatesDialogService, DictionaryService,
        TRANSLATION_KEYS, DOCUMENT_KINDS, OrganisationalUnitsService, JsaDocumentFormService
    ) {
        var vm = this;
        vm.getDocuments = getDocuments;
        vm.resetUploadForm = resetUploadForm;
        vm.uploadText = uploadText;
        vm.getDocumentSkipAddress = getDocumentSkipAddress;
        vm.showDocumentVerification = showDocumentVerification;
        vm.addDocumentJsa = addDocumentJsa;
        vm.getLanguageItemText = getLanguageItemText;
        vm.getLanguageItemTextByCode = getLanguageItemTextByCode;
        vm.aiDetectionPoolExhausted = aiDetectionPoolExhausted;
        vm.aiDetectionDisabled = aiDetectionDisabled;

        vm.selectedItem = [];
        vm.searchText = [];
        vm.authorsSimplifiedFields = AccountService.account.clientSettings.authorsSimplifiedFields;
        vm.jsa = AccountService.getClientSettings().jsaIntegration;
        vm.account = AccountService.account;
        vm.userId = vm.account.id;
        vm.availableUnits = [];

        vm.typeOfDocuments = DOCUMENT_KINDS.jsaDocumentKinds;

        vm.addAuthor = addAuthor;
        vm.removeAuthor = removeAuthor;
        vm.searchAuthors = function(searchText) {return JsaService.getAuthorsAutocompleteItems(searchText, vm.onUploadObj.authors)};
        vm.selectedAuthorChanged = selectedAuthorChanged;
        vm.selectedStudyChanged = selectedStudyChanged;
        vm.shouldRequireIndexNumber = shouldRequireIndexNumber;
        vm.formatAuthor = JsaDocumentFormService.formatAuthor;

        vm.searchValuePromoter;
        vm.searchPromoters = JsaService.getPromotersAutocompleteItems;
        vm.selectPromoter = selectPromoter;

        vm.querySearchForLanguage;
        vm.searchLanguages = DictionaryService.getLanguagesJsaAutocompleteItems;
        vm.selectLanguage = selectLanguage;

        if (AccountService.role.isLecturer()) {
            JsaService.getPromoterByUserId(vm.account.id).then(function (response) {
                if (response.data) {
                    vm.selectedPromoter = response.data;
                    vm.promoterNotEditable = true;
                }
            });
        }

        function showDocumentVerification() {
            return AccountService.account.authorities.includes("PERM_USER_DOCUMENT_VERIFICATION");
        }

        function getLanguageItemText(item) {
            return $translate.instant(TRANSLATION_KEYS.dictionaryLanguage + item.code);
        }

        function shouldRequireIndexNumber() {
            return vm.onUploadObj.documentKindId !== DOCUMENT_KINDS.PHD.id;
        }

        function selectedAuthorChanged(item, index) {
            JsaDocumentFormService.handleSelectedAuthorChange(item, index, vm);
        }

        function selectedStudyChanged() {
            JsaDocumentFormService.refreshAvailableUnits(vm);
        }

        function getLanguageItemTextByCode(code) {
            return $translate.instant(code);
        }

        function selectPromoter(promoter) {
            if (promoter && promoter.name) {
                vm.onUploadObj.promoter = promoter;
            } else {
                vm.onUploadObj.promoter = null;
            }
        }

        function selectLanguage(language) {
            if (language && language.code) {
                vm.onUploadObj.language = language;
            } else {
                vm.onUploadObj.language = null;
            }
        }

        function setCoordinatorId() {
            vm.onUploadObj.coordinatorId = vm.onUploadObj.promoter;
        }

        function addAuthor() {
            if (!vm.onUploadObj.authors) {
                vm.onUploadObj.authors = [];
            }
            vm.onUploadObj.authors.push({});
        }

        function removeAuthor(event, item) {
            vm.onUploadObj.authors.splice(item, 1);
        }

        function aiDetectionDisabled() {
            return (vm.onUploadObj && vm.onUploadObj.aiDetectionInfo && vm.onUploadObj.aiDetectionInfo.defaultValue === 'alwaysEnabled')
                || vm.aiDetectionPoolExhausted();
        }

        function aiDetectionPoolExhausted() {

            if (AccountService.account.contractAiDetectionEnabled) {
                if (vm.onUploadObj && vm.onUploadObj.aiDetectionPoolInfo && vm.onUploadObj.aiDetectionPoolInfo.aiDetectionPoolEnabled) {
                    if (vm.onUploadObj.size > vm.onUploadObj.aiDetectionPoolInfo.contNumOfAiDetectionLeft) {
                        vm.onUploadObj.aiDetection = false;
                        return true;
                    }
                }
            }
            return false;
        }


        function uploadText() {
            vm.onUploadText();
        }

        function getDocuments() {
            vm.onReloadDocuments();
        }

        function resetUploadForm() {
            vm.onUploadObj = {
                documentSkipAddresses: []
            };
            vm.onUploadIsActive = false;
            vm.onUploadTextFlag = false;
            vm.isLoading = false;
            vm.onGetTheFiles(null, {});
            vm.selectedItem = [];
            vm.searchText = [];
        }

        function addDocument(obj) {
            if (vm.selectedPromoter) {
                vm.selectPromoter(vm.selectedPromoter);
            }
            //check if coordinator's fields are simplified
            if (vm.onIsLecturer) {
                vm.onIsLecturer ? setCoordinatorId() : '';
            } else if (angular.isDefined(obj.coordinatorDTO)) {
                obj.coordinatorDTO.lastName = vm.authorsSimplifiedFields ? '' : obj.coordinatorDTO.lastName;
            }
            vm.onUploadTextFlag = false;
            vm.isLoading = true;

            $mdDialog.show($mdDialog.confirm()
                .title($translate.instant("document.messages.checknow"))
                .textContent(vm.checkInfo)
                .ariaLabel(vm.checkInfo)
                .ok($translate.instant("global.msg.ok"))
                .cancel($translate.instant("global.button.cancel"))
            ).then(function () {
                resetUploadForm();
                var alert = $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(false)
                    .escapeToClose(false)
                    .title($translate.instant("global.messages.jsa.uploading"))
                    .textContent($translate.instant("global.messages.jsa.uploading.content"));

                $mdDialog.show(alert);

                DuplicatesDialogService.saveJSADocumentWithPopUp(obj, alert);
            });
        }

        function addDocumentJsa(obj, form) {
            for (var i = 0; i < form.$$controls.length; i++) {
                form.$$controls[i].$touched = true;
            }
            if (form.$invalid) {
                return;
            }
            JsaDocumentFormService.inferOrganisationalUnitId(vm).then(function (organisationalUnitId) {
                obj.organisationalUnitId = organisationalUnitId;
            }).then(function () {
                addDocument(obj);
            });
        }

        function getDocumentSkipAddress(chip) {
            return {
                address: chip
            };
        }
    }

    angular.module('plagiat2017App')
        .component('jsaDocumentForm', {
            templateUrl: 'app/account/documents/document/document-form/jsa-document-form/jsa-document-form.template.html',
            controller: JsaDocumentFormUniversalController,
            controllerAs: 'vm',
            bindings: {
                onUploadIsActive: '=',
                onUploadTextFlag: '=',
                onUploadObj: '<',
                onReloadDocuments: '<',
                onGetTheFiles: '<',
                onUploadText: '<',
                isLoading: '=',
                onGetConfiguration: '<',
                onGetConfigurationOffChecking: '<',
                onIsLecturer: '<',
                onIsOperator: '<',
                isJsaDisabled: '<'
            }
        });

})();
