(function () {
    'use strict';

    function DocumentFormUniversalController(
        $scope, $q, _, $rootScope, $state, $translate, $mdDialog, DocumentClientModel, Utils,
        DocumentService, ContractService, $log, $timeout, AccountService, PlagiatValidationService, DuplicatesDialogService,
        DocumentFormService, PERMISSIONS, AUTO_TRANSLATIONS_LANGS, CodeService, ModalService
    ) {
        var vm = this;
        vm.getDocuments = getDocuments;
        vm.resetUploadForm = resetUploadForm;
        vm.missingTokens;
        vm.uploadText = uploadText;

        vm.isCoordinatorVisible = isCoordinatorVisible;
        vm.canUserChangeCoordinator = canUserChangeCoordinator;
        vm.coordinatorAutocompleteAvailable = coordinatorAutocompleteAvailable;
        vm.aiDetectionPoolExhausted = aiDetectionPoolExhausted;
        vm.aiDetectionDisabled = aiDetectionDisabled;

        vm.autoTranslationLangs = Utils.sortAutoTranslationsLanguages(AUTO_TRANSLATIONS_LANGS, $translate);
        
        vm.addAuthor = addAuthor;
        vm.removeAuthor = removeAuthor;
        vm.authorsArr = [];
        vm.authorIndex = 0;
        vm.addWithoutChecking = addWithoutChecking;
        vm.addDocumentUniversal = addDocumentUniversal;
        vm.checkDocumentCodeAccount = checkDocumentCodeAccount;
        vm.selectedItem  = null;
        vm.searchText    = null;
        vm.coordinatorNotApplicable = AccountService.account.clientSettings.coordinatorNotRequiredByDefault;
        vm.querySearch = querySearch;
        vm.getConfiguration = getConfiguration;
        vm.getConfigurationOffChecking = getConfigurationOffChecking;
        vm.setOnUploadObj = setOnUploadObj;
        vm.verifyAiDetectionVsAutoTranslation = verifyAiDetectionVsAutoTranslation;
        vm.selectedItemChange = selectedItemChange;
        vm.orgUnitId = !angular.isUndefined(AccountService.account.organisationalUnitId) ? AccountService.account.organisationalUnitId : 0;
        vm.getDocumentSkipAddress = getDocumentSkipAddress;
        vm.authorsSimplifiedFields = AccountService.account.clientSettings.authorsSimplifiedFields;
        vm.autoTranslationsLangSelection = AccountService.account.clientSettings.autoTranslationsLangSelection;
        vm.jsa = AccountService.getClientSettings().jsaIntegration ;
        vm.account = AccountService.account;
        vm.showDocumentVerification = showDocumentVerification;
        vm.canChangeOrganisationUnit = AccountService.getPermision(PERMISSIONS.PERM_USER_CAN_CHANGE_ORG_UNIT_DURING_ADD_DOCUMENT) || AccountService.role.isAdmin();

        vm.clientTypePublishingHouse = AccountService.clientType.isPublishingHouse();
        vm.clientTypeSchool = AccountService.clientType.isSchool();
        vm.shouldShowCodeAccountCheckModal = AccountService.clientType.isCodeAccount() && !AccountService.role.isAdmin();
        vm.hideAddWithoutChecking = AccountService.account.clientSettings.hideAddWithoutChecking;

        vm.isStudent = isStudent;
        vm.isAdmin = AccountService.account;
        vm.priorityCode = undefined;

        this.$onInit = function () {
            populateOrganisationUnit();
        };

        function showDocumentVerification(){
            return AccountService.account.authorities.includes("PERM_USER_DOCUMENT_VERIFICATION");
        }

        function setOnUploadObj(){
            vm.onUploadObj.addWithoutChecking ? getConfigurationOffChecking() : getConfiguration();
        }

        function verifyAiDetectionVsAutoTranslation(val) {
            if ('aiDetection' === val) {
                vm.onUploadObj.autoTranslations = 0;
            }
            if ('autoTranslation' === val) {
                vm.onUploadObj.aiDetection = 0;
            }
        }

        function getConfiguration(){
            vm.onGetConfiguration(vm.onUploadObj);
        }

        function getConfigurationOffChecking(){
            vm.onGetConfigurationOffChecking(vm.onUploadObj);
        }


        function selectedItemChange(item) {
            vm.onUploadObj.coordinatorId = angular.isUndefined(item) ? null : item.value.coordinatorId;
            vm.onUploadObj.coordinatorDTO = {};
        }

        function lowercase(string) {
            return angular.isString(string) ? string.toLowerCase() : string;
        }

        function querySearch(query) {
            vm.promoters = vm.onUploadObj.coordinatorInfo.selectItems;
            var results = query ? _.filter(vm.promoters, function (o) {
                return lowercase(o.label).indexOf(lowercase(query)) !== -1;
            }) : vm.promoters;
            var deferred = $q.defer();
            $timeout(function () {
                deferred.resolve(results);
            }, Math.random() * 1000, false);
            return deferred.promise;
        }

        function addAuthor(){
            vm.authorIndex++;
            vm.authorsArr.push(vm.authorIndex);
        }

        function removeAuthor(event, item){
            $(event.target).parent().parent().remove();
            if(angular.isDefined(vm.onUploadObj.authors) && angular.isDefined(vm.onUploadObj.authors[item])){
                delete vm.onUploadObj.authors[item];
            }
        }

        function uploadText() {
            vm.onUploadText();
        }

        function getDocuments() {
            vm.onReloadDocuments();
        }

        function resetUploadForm() {
            vm.onUploadObj = {
                documentSkipAddresses: []
            };
            vm.onUploadIsActive = false;
            vm.onUploadTextFlag = false;
            vm.isLoading = false;
            vm.onGetTheFiles(null, {});
            vm.authorIndex = 0;
            vm.authorsArr.length = 0;
            vm.selectedItem  = null;
            vm.searchText    = null;
        }

        function addWithoutChecking(obj, ev, form) {
            for (var i = 0; i < form.$$controls.length; i++) {
                form.$$controls[i].$touched = true;
            }
            if (form.$invalid) {
                return;
            }
            //check if coordinator's fields are simplified
            if (angular.isDefined(obj.coordinatorDTO)) {
                obj.coordinatorDTO.lastName = vm.authorsSimplifiedFields ? '' : obj.coordinatorDTO.lastName;
            }
            obj.title = obj.docuTitle;
            vm.onUploadTextFlag = false;

            $mdDialog.show($mdDialog.confirm()
                .title($translate.instant("document.messages.adwithoutchecking"))
                .textContent($translate.instant("document.messages.adwithoutcheckinginfo"))
                .ariaLabel($translate.instant("document.messages.adwithoutcheckinginfo"))
                .targetEvent(ev)
                .ok('Ok')
                .cancel($translate.instant("global.button.cancel"))
                ).then(function () {
                    vm.isLoading = true;
                    DocumentService.operator.save(obj).then(function (responseData) {
                        resetUploadForm();
                        $mdDialog.show(
                          $mdDialog.confirm()
                          .clickOutsideToClose(true)
                          .title('')
                          .textContent($translate.instant("global.messages.info.actionsuccess"))
                          .ariaLabel('')
                          .ok('Ok')
                          .targetEvent(ev)
                          );
                        getDocuments();
                        ContractService.broadcast();
                    }).catch(function (response) {
                        vm.isLoading = false;
                        PlagiatValidationService.getModalError(response.data);
                    });
                });
        }

        function checkDocumentCodeAccount(obj, ev, form) {
            if (!isFormValid(form)) {
                return;
            }
            if (vm.priorityCode) {
                checkDocumentByPriorityCode(obj, ev, form);
                return;
            }
            var addDocumentCallback;
            if(!vm.onUploadObj.addWithoutChecking){
                addDocumentCallback = function(codeDialogParameters) {
                    if (codeDialogParameters && codeDialogParameters.generateCheckId) {
                        obj.generateCheckId = true;
                    }
                    if (codeDialogParameters && codeDialogParameters.codeId) {
                        obj.codeId = codeDialogParameters.codeId;
                    }
                    if (codeDialogParameters && codeDialogParameters.checkLater) {
                        obj.checkLater = true;
                    }
                    return vm.addDocumentUniversal(obj, ev, form);
                };
                var checkDocumentCallback = function(codeDialogParameters) {
                    var documentId = codeDialogParameters.documentId;
                    var codeId = null;
                    if (codeDialogParameters && codeDialogParameters.codeId) {
                        codeId = codeDialogParameters.codeId;
                    }
                    DocumentService.operator.checkDocument(documentId, codeId).then(function () {
                        getDocuments();
                        ContractService.broadcast();
                    });

                };
                $mdDialog.show({
                    templateUrl: 'app/account/documents/document-code-check/document-check-code.dialog.html',
                    clickOutsideToClose: false,
                    locals: {
                        items: {
                            addDocumentCallback: addDocumentCallback,
                            checkDocumentCallback: checkDocumentCallback,
                            fileId: vm.onUploadObj.fileId,
                            size: vm.onUploadObj.size
                        }
                    },
                    controllerAs: 'vm',
                    controller: DialogController
                });
            }
        }

        function checkDocumentByPriorityCode(uploadObj, event, form) {
            uploadObj.checkLater = true;
            vm.addDocumentUniversal(uploadObj, event, form).then(function (documentId) {
                CodeService.findCodeForDocumentChecking(documentId, true).then(
                  function (codeForDocumentCheckingResponse) {
                      var codeForDocumentChecking = codeForDocumentCheckingResponse.data;
                      if (codeForDocumentChecking) {
                          DocumentService.operator.checkDocument(documentId, codeForDocumentChecking.codeId).then(function () {
                              getDocuments();
                              ContractService.broadcast();
                          });
                      } else {
                          ModalService.showConfirmModal("document.code.no-fitting-code.buy-proposal",
                            {customCancelButton: true}).then(
                            function () {
                                $state.go('code-payments', {documentId: documentId, priorityCode: true});
                            }
                          )
                      }
                  }
                )
            });
        }

        function DialogController($scope, $mdDialog, items) {
            $scope.items = items;
        }

        function isFormValid(form) {
            for (var i = 0; i < form.$$controls.length; i++) {
                form.$$controls[i].$touched = true;
            }
            return !form.$invalid;
        }

        function addDocumentUniversal(obj, ev, form) {
            if (!isFormValid(form)) {
                return;
            }
            //check if coordinator's fields are simplified
            if (angular.isDefined(obj.coordinatorDTO)) {
                obj.coordinatorDTO.lastName = vm.authorsSimplifiedFields ? '' : obj.coordinatorDTO.lastName;
            }
            obj.title = obj.docuTitle;
            vm.onUploadTextFlag = false;
            vm.isLoading = true;

            return $mdDialog.show($mdDialog.confirm()
                .title($translate.instant("document.messages.checknow"))
                .textContent(vm.checkInfo)
                .ariaLabel(vm.checkInfo)
                .targetEvent(ev)
                .ok($translate.instant("global.msg.ok"))
                .cancel($translate.instant("global.button.cancel"))
            ).then(function () {
                if (vm.account.authorities.includes('PERM_USER_CHECK_DOCUMENT_METRIC_SIMILARITY_BEFORE_ADD')) {
                    return DocumentService.operator.searchSimilar(obj).then(function (responseData) {
                        if (typeof responseData.data !== "undefined" && responseData.data.length != 0) {

                            resetUploadForm();
                            return $mdDialog.show(
                                $mdDialog.confirm(
                                    {
                                        onComplete: function afterShowAnimation() {
                                            var $dialog = angular.element(document.querySelector('md-dialog'));

                                            var $actionsSection = $dialog.find('md-dialog-actions');
                                            angular.element($actionsSection).addClass('md-dialog-action-section-similar-documents');

                                            var $actionsContent = $dialog.find('md-dialog-content');
                                            angular.element($actionsContent).addClass('md-dialog-content-section-similar-documents');

                                            var wishToProceedLine = angular.element('<span>' + $translate.instant("document.messages.metric.similarity.wish.to.proceed") + '</span>');
                                            angular.element(wishToProceedLine).addClass('md-title md-dialog-content-section-similar-documents-proceed');
                                            angular.element($actionsSection).prepend(wishToProceedLine);
                                        }
                                    }
                                ).title($translate.instant("document.messages.metric.similarity.before.adding"))
                                .htmlContent(formatSimilarDocumentsList($translate, responseData))
                                .ariaLabel('')
                                .targetEvent(ev)
                                .ok($translate.instant("global.msg.ok"))
                                .cancel($translate.instant("global.button.cancel"))
                            ).then(function () {
                                resetUploadForm();
                                return DuplicatesDialogService.saveDocumentWithPopUp(obj, ev);
                            }, function () {
                                // console.log("Operation of adding document has been aborted");
                            });
                        } else {
                            resetUploadForm();
                            return DuplicatesDialogService.saveDocumentWithPopUp(obj, ev);
                        }

                    }).catch(function (response) {
                            vm.isLoading = false;
                            PlagiatValidationService.getModalError(response.data);
                    });
                }
                else {
                    resetUploadForm();
                    return DuplicatesDialogService.saveDocumentWithPopUp(obj, ev).catch(function (response) {
                        vm.isLoading = false;
                        PlagiatValidationService.getModalError(response.data);
                    });
                }
            }, function () {
                vm.isLoading = false;
            });
        }

        function getDocumentSkipAddress(chip) {
            return DocumentFormService.documentSkipAddress(chip, vm);
        }

        function isCoordinatorVisible() {
            if (vm.clientTypePublishingHouse) {
                return false;
            }
            return vm.onUploadObj
                && vm.onUploadObj.coordinatorInfo
                && vm.onUploadObj.coordinatorInfo.isPresent
                && vm.onUploadObj.coordinatorInfo.selectItems.length === 0;
        }

        function canUserChangeCoordinator() {
            if(vm.onIsLecturer) {
                return AccountService.getClientSettings().userTeacherCanChangePromoterDuringAddDocument;
            } else {
                return true;
            }
        }

        function coordinatorAutocompleteAvailable() {
            return vm.onUploadObj && vm.onUploadObj.coordinatorInfo && vm.onUploadObj.coordinatorInfo.isPresent && vm.onUploadObj.coordinatorInfo.selectItems.length > 0 && vm.canUserChangeCoordinator();
        }

        function aiDetectionDisabled() {
            return (vm.onUploadObj && vm.onUploadObj.aiDetectionInfo && vm.onUploadObj.aiDetectionInfo.defaultValue === 'alwaysEnabled')
                || vm.aiDetectionPoolExhausted();
        }

        function aiDetectionPoolExhausted() {

            if (AccountService.account.contractAiDetectionEnabled) {
                if (vm.onUploadObj && vm.onUploadObj.aiDetectionPoolInfo && vm.onUploadObj.aiDetectionPoolInfo.aiDetectionPoolEnabled) {
                    if (vm.onUploadObj.size > vm.onUploadObj.aiDetectionPoolInfo.contNumOfAiDetectionLeft) {
                        vm.onUploadObj.aiDetection = false;
                        return true;
                    }
                }
            }
            return false;
        }

        function isStudent() {
            return vm.onIsStudent;
        }

        vm.querySearchOrganizations = function () {
            return vm.searchOrganizationText ? vm.onUploadObj.orgUnitInfo.selectItems.filter(function (org) {
                return org.label.toLowerCase().includes(vm.searchOrganizationText.toLowerCase());
            }) : vm.onUploadObj.orgUnitInfo.selectItems;
        };

        function populateOrganisationUnit() {
            vm.selectedOrganization = vm.onUploadObj.orgUnitInfo.selectItems.find(function (org) {
                return org.value === vm.orgUnitId;
            });
            vm.selectedOrganizationChange(vm.selectedOrganization);
        }

        vm.selectedOrganizationChange = function (item) {
            if (item) {
                vm.onUploadObj.orgUnitId = item.value;
            } else {
                vm.onUploadObj.orgUnitId = null;
            }
        };
    }

    function formatSimilarDocumentsList($translate, responseData) {
        var similarDocumentsList="";
        responseData.data.forEach(function (data) {
            similarDocumentsList = similarDocumentsList + "<br>" +
                $translate.instant('document.report.similarity.title') + ":<b><i>&nbsp;" + data.docuTitle + "&nbsp;</i></b>";
            var lastName = data.authors.author.lastName ? " " + data.authors.author.lastName : "";
            similarDocumentsList = similarDocumentsList + "(" + data.authors.author.firstName + lastName + ")";
            similarDocumentsList = similarDocumentsList + " " + $translate.instant("document.model.size") +":"+data.docuSize +' ' + $translate.instant('document.table.characters');
            similarDocumentsList = similarDocumentsList + "&nbsp;" + $translate.instant("document.model.status") + ": " + $translate.instant('document.table.statuses.' + data.docuStatus) + "<br>";
        });
        similarDocumentsList=similarDocumentsList+"<br>";
        return similarDocumentsList
    }

    angular.module('plagiat2017App')
    .component('documentFormUniversal', {
        templateUrl: 'app/account/documents/document/document-form/document-form-universal.template.html',
        controller: DocumentFormUniversalController,
        bindings: {
            onUploadIsActive: '=',
            onUploadTextFlag: '=',
            onUploadObj: '<',
            onReloadDocuments: '<',
            onGetTheFiles: '<',
            onUploadText: '<',
            isLoading: '=',
            onGetConfiguration: '<',
            onGetConfigurationOffChecking: '<',
            onIsLecturer: '<',
            onIsStudent: '<',
            onIsOperator: '<'
        }
    });

})();
