angular.module('plagiat2017App')
    .component('landingPageInvoiceModal', {
        templateUrl: 'app/landing/landing-page/landing-page-invoice.template.html',
        controller: LandingPageInvoiceController,
        controllerAs: 'vm'
    });

LandingPageInvoiceController.$inject = ['$state', '$mdDialog', '$timeout', 'items', 'Auth', 'DetectInstanceService', 'ROMANIAN_CITIES', 'ROMANIAN_COUNTIES'];

function LandingPageInvoiceController($state, $mdDialog, $timeout, items, Auth, DetectInstanceService, ROMANIAN_CITIES, ROMANIAN_COUNTIES) {
    var vm = this;

    vm.closeDialog = closeDialog;
    vm.goUpload = goUpload;

    vm.invoice = {};
    vm.isLoading = false;
    vm.instanceLang = DetectInstanceService.getLandingLang();
    vm.isRomania = vm.instanceLang == 'ro';
    vm.romanianCities = ROMANIAN_CITIES;
    vm.romanianCounties = ROMANIAN_COUNTIES;

    function goUpload() {
        vm.isLoading = true;
        items.onUploadWithInvoiceData(vm.invoice);
    }

    function closeDialog() {
        $mdDialog.hide();
        $timeout(function () {
            Auth.logout();
            switch (vm.instanceLang) {
                case 'ro':
                    $state.go('landing-page-ro', {key: 'error'});
                    break;
                case 'en':
                    $state.go('landing-page-int', {key: 'error'});
                    break;
                default:
                    $state.go('landing-page', {key: 'error'});
            }
        }, 1);
    }
}
