(function() {
	'use strict';

	function PaymentsInvoicesDetailsController($log, $state, DocumentService, AccountService) {
		var vm = this;
	}

	angular
	.module('plagiat2017App')
	.component('paymentsInvoicesDetails', {
			templateUrl : 'app/account/payments/payments-invoices-details/payments-invoices-details.template.html',
			controller : PaymentsInvoicesDetailsController,
			bindings: {
				onInvoice: '<',
				onInvoiceDetails: '<'
			}
		});

})();
