(function () {
    'use strict';

    function ProfileFormController($scope, $mdDialog, AccountService, $log, ModalService, PlagiatValidationService,
                                   $translatePartialLoader, $translate, $localStorage) {
        var vm = this;
        vm.closeDialog = closeDialog;
        vm.deleteAccountButtonClicked = deleteAccountButtonClicked;
        vm.logoutToRootInfo = $localStorage.logoutToRootInfo;
        vm.user = { changeEmail: false };
        vm.account = AccountService.account;
        vm.user.userName = vm.account.firstName;
        vm.user.userSurname = vm.account.lastName;
        vm.user.userEmail = vm.account.email;
        vm.user.userWantsNotifications = vm.account.userWantsNotifications;
        vm.saveData = saveData;
        vm.allUsersCanDeleteAccounts = AccountService.getClientSettings() && AccountService.getClientSettings().allUsersCanDeleteAccounts;
        vm.canNotChangeNotificationSetting = (
            AccountService.role.isStudent()
            && AccountService.clientType.isSchool() 
            && AccountService.getClientSettings() 
            && !AccountService.getClientSettings().sendAlertToStudent
        );
        vm.isIndividual = AccountService.role.isIndividual();
        vm.isStudent = AccountService.role.isStudent();
        vm.errorMessage = undefined;

        function closeDialog() {
            $mdDialog.hide();
        }

        function deleteAccountButtonClicked() {
            $mdDialog.hide();
            AccountService.sendAccountDeletionConfirmationEmail();
            ModalService.showDialogModal("delete-account.dialog.email-sent");
        }

        function saveData() {
            AccountService.setData(vm.user).then(function () {
            	vm.errorMessage = undefined;
                $mdDialog.hide();
                AccountService.account.firstName = vm.user.userName;
                AccountService.account.lastName = vm.user.userSurname;
                AccountService.account.userWantsNotifications = vm.user.userWantsNotifications;
                if(vm.user.changeEmail) {
                    ModalService.showSuccessModal("global.email.changed");
                }
            }, function(errResponse){
            	vm.errorMessage = PlagiatValidationService.getTextError(errResponse.data.code);
        	});
        }
        $translatePartialLoader.addPart('delete-account');
        $translate.refresh();
    }

    angular.module('plagiat2017App')
    .component('profileForm', {
        templateUrl: 'app/components/profile/profile-form/profile-form.template.html',
        controller: ProfileFormController
    });

})();
